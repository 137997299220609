interface IDFMemberShipConfig {
  redirectSuccessUrl: string;
  redirectFailureUrl: string;
  clientId: string;
  secretId: string;
  domain: string;
  logoUri: string;
  accessDeniedPage: string;
  status: string;
}

interface IDFStartupConfig {
  portalId: string;
  clientRedirectUrl: string;
  callbacks: any;
  auth0Settings: {
    ssoLogout: boolean;
    logoutRedirectUrl: string
  },
  loginTitle: string
}

enum CeStatus {
  OK = "OK",
  REJECT = "REJECT",
  FORBIDDEN = "FORBIDDEN",
  LOGOUT = "LOGOUT",
  GRANTED = "GRANTED",
}

interface ICeStatus {
  redirectUrl: string;
  status: CeStatus;
}

const apiUrl = process.env.API_URL;
const ceApiUrl = process.env.CE_API_URL;

export default class DFMembership {

  private dfConfig: IDFMemberShipConfig;
  private startupConfig: IDFStartupConfig;
  private callbacks: any;

  private sessionKey = "df_session_id";
  private authErrorKey = "error_code";
  private storageConfigKey = "df-config";
  private storageRedirectKey = "df-redirect";
  private storagePersonalizationKey = "df-personalization";
  private dfmClassKey = "dfm-";

  private auth0: any;
  private auth0WebAuth: any;
  private authLockPasswordless: any;

  private authInputClass = ".auth0-lock-input-block";
  private errorContainer: HTMLElement;

  private noContentStyle = "df-no-content";
  private dfStyles = "df-styles";

  constructor() {}

  get config() {
    return this.dfConfig;
  }

  set config(settings) {
    this.dfConfig = settings;
  }

  gate(startupConfig: IDFStartupConfig) {
    this.startupConfig = startupConfig;
    this.callbacks = startupConfig.callbacks;
    this.addDfStyles();

    this.hideBody();

    this.loadAuthConfig().then((res) => {
      if (res) {
        this.initChecks().then((status) => {
          switch (status) {
            case CeStatus.FORBIDDEN:
              window.location.href = this.dfConfig.accessDeniedPage;
              return Promise.reject();

            case CeStatus.REJECT:
              if (!window.location.href.includes("#login")) {
                localStorage.setItem(
                  this.storageRedirectKey,
                  window.location.href
                );
                window.location.href = window.location.origin + "/#login";
              } else {
                this.loadAuthLock();
                this.showBody();
              }
              return Promise.reject();

            case CeStatus.LOGOUT:
              this.appLogout()
                .finally(() => {
                  this.triggerAppEvent('onLogoutComplete');
                  if (this.startupConfig.auth0Settings?.ssoLogout) {
                    this.auth0Logout();
                  } else {
                    window.location.href = window.location.origin;
                  }
                });
              return Promise.reject();

            case CeStatus.OK:
              this.showBody();
              return Promise.resolve();

            case CeStatus.GRANTED:
              this.showBody();
              this.loadPersonalization();
              return Promise.resolve();

            default:
              console.log(status);
              return Promise.reject();
          }
        })
        .then((res) => {
          this.triggerAppEvent('onFormReady');
          // window.history.replaceState = new Proxy(window.history.pushState, {
          //   apply: (target, thisArg, argArray) => {
          //     this.checkForFeatures();
          //     return target.apply(thisArg, argArray);
          //   },
          // });
        });
      }
    });
  }

  logout() {
    window.location.href = window.location.href + "#logout";
    window.location.reload();
  }

  goToBilling() {
    window.location.href = window.location.href + "#billing";
    this.loadBillingProfile();
  }

  private initChecks(): Promise<CeStatus> {
    if (window.location.href.includes("#login")) {
      return Promise.resolve(CeStatus.REJECT);
    }

    if (window.location.href.includes("#logout")) {
      return Promise.resolve(CeStatus.LOGOUT);
    }

    const checkError = this.checkParams(this.authErrorKey);
    if (checkError) {
      return Promise.resolve(CeStatus.FORBIDDEN);
    }

    return this.checkSession().then((status) => {
      return Promise.resolve(status.status);
    });
  }

  private hideBody() {
    const stle = document.createElement("style");
    stle.setAttribute("id", this.noContentStyle);
    stle.textContent = `body {display: none !important;}`;

    document.head.appendChild(stle);
  }

  private showBody() {
    const stle = document.querySelector("#" + this.noContentStyle);
    if (stle) {
      document.head.removeChild(stle);
    }
    this.checkForFeatures();
  }

  private checkForFeatures() {
    if (window.location.href.includes("#billing")) {
      this.loadBillingProfile();
    }
  }

  private addDfStyles() {
    const dfCss = document.head.querySelector("#" + this.dfStyles);
    if (dfCss) {
      return;
    }

    const initCss = document.createElement("style");
    initCss.setAttribute("id", this.dfStyles);
    initCss.textContent = `.auth0-lock-header-logo, .auth0-lock-badge-bottom, .auth0-lock-input-show-password{
        display: none !important;
        }`;

    document.head.appendChild(initCss);
  }

  private auth0Logout() {
    this.loadAuth0WebAuth().then(() => {
      this.auth0WebAuth.logout({
        client_id: this.dfConfig.clientId,
        returnTo: this.startupConfig.auth0Settings?.logoutRedirectUrl || window.location.origin
      });
    });
  }

  private appLogout() {
    return fetch(
      `${apiUrl}/member-auth/logout/${this.getCookie(this.sessionKey)}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(async (response) => {
      if (response.status === 500) {
        return Promise.resolve(false);
      }
      localStorage.removeItem(this.storageConfigKey);
      localStorage.removeItem(this.storageRedirectKey);
      localStorage.removeItem(this.storagePersonalizationKey);
      document.cookie = this.sessionKey + "=; Max-Age=-99999999;";
      return Promise.resolve(true);
    });
  }

  private loadAuthConfig(): Promise<boolean> {
    const checkSTorage = JSON.parse(
      localStorage.getItem(this.storageConfigKey)
    ) as IDFMemberShipConfig;
    if (checkSTorage) {
      this.dfConfig = checkSTorage;
      this.getConfig(false);
      return Promise.resolve(true);
    }

    return this.getConfig();
  }

  private getConfig(isSync = true): Promise<boolean> {
    return fetch(
      `${apiUrl}/auth0/portal/${this.startupConfig.portalId}/config/properties`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(async (response) => {
      if (isSync) {
        this.dfConfig = (await response.json()) as IDFMemberShipConfig;
        this.setLocalStorageConfig();
        return Promise.resolve(true);
      } else {
        return response.json().then((res) => {
          this.dfConfig = res;
          this.setLocalStorageConfig();
          return Promise.resolve(true);
        });
      }
    });
  }

  private loadPersonalization() {
    const dfmFields = document.querySelectorAll(
      `[class*='${this.dfmClassKey}']`
    );
    let dfmClasses = [];
    dfmFields.forEach((el) => {
      el.classList.forEach((cl) => {
        if (cl.includes(this.dfmClassKey)) {
          const key = cl.replace(this.dfmClassKey, "");
          dfmClasses.push(key);
        }
      });
    });

    const uniqueClasses = [...new Set(dfmClasses)];

    this.loadPersonalizationFromLocalStorage();

    this.getPersonlization(uniqueClasses).then((res) => {
      this.loadPersonalizationFromLocalStorage();
    });
  }

  private loadPersonalizationFromLocalStorage() {
    const localStoragePersonalization =
      localStorage.getItem(this.storagePersonalizationKey) &&
      (JSON.parse(
        localStorage.getItem(this.storagePersonalizationKey)
      ) as Object);

    if (localStoragePersonalization) {
      const values = Object.keys(localStoragePersonalization);
      values.forEach((val) => {
        const getElements = document.querySelectorAll(
          `.${this.dfmClassKey}` + val
        ) as NodeListOf<HTMLElement> | NodeListOf<HTMLInputElement>;
        if (getElements && getElements.length) {
          getElements.forEach((el) => {
            el instanceof HTMLInputElement
              ? (el.value = localStoragePersonalization[val])
              : (el.innerHTML = localStoragePersonalization[val]);
          });
        }
      });
    }
  }

  private getPersonlization(keys: string[]): Promise<boolean> {
    return fetch(`${apiUrl}/member-auth/user-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: this.getCookie(this.sessionKey),
        fields: keys,
      }),
    }).then(async (response) => {
      return response.json().then((res) => {
        this.setLocalStorageKey(
          this.storagePersonalizationKey,
          res && res.properties
        );
        return Promise.resolve(true);
      });
    });
  }

  private loadBillingProfile() {
    const dialogOverlay = document.createElement("div");
    dialogOverlay.style.position = "fixed";
    dialogOverlay.style.background = "rgba(128,128,128,0.31)";
    dialogOverlay.style.top = "0";
    dialogOverlay.style.left = "0";
    dialogOverlay.style.width = "100vw";
    dialogOverlay.style.height = "100vh";
    dialogOverlay.style.zIndex = "99998";

    // dialogOverlay.onclick = (ev) => {
    //   ev.preventDefault();
    //   ev.stopPropagation();
    //   dialogOverlay.remove();
    // };

    const dialog = document.createElement("div");
    dialog.style.width = "95vw";
    dialog.style.height = "95vh";
    dialog.style.position = "fixed";
    dialog.style.top = "50%";
    dialog.style.left = "50%";
    dialog.style.marginTop = "calc(-95vh / 2)";
    dialog.style.marginLeft = "calc(-95vw / 2)";
    dialog.style.background = "white";
    dialog.style.borderRadius = "5px";
    dialog.style.zIndex = "9999999";
    dialog.style.boxShadow = "0px 0px 5px 1px grey";

    const closeBtnContainer = document.createElement("div");
    closeBtnContainer.style.position = "relative";
    closeBtnContainer.style.width = "100%";
    closeBtnContainer.style.height = "50px";
    closeBtnContainer.style.verticalAlign = "middle";

    const closeBtn = document.createElement("div");
    closeBtn.style.position = "absolute";
    closeBtn.style.top = "0px";
    closeBtn.style.right = "10px";
    closeBtn.style.cursor = "pointer";
    closeBtn.style.fontSize = "35px";
    closeBtn.innerHTML = `×`;
    closeBtn.onclick = () => {
      dialogOverlay.remove();
      window.history.replaceState({}, document.title, window.location.pathname);
    };

    closeBtn.onmouseenter = () => {
      closeBtn.style.textShadow = "0px 1px 3px gray";
    };

    closeBtn.onmouseleave = () => {
      closeBtn.style.textShadow = "none";
    };

    closeBtnContainer.appendChild(closeBtn);
    dialog.appendChild(closeBtnContainer);
    dialogOverlay.appendChild(dialog);

    document.body.appendChild(dialogOverlay);

    const billingContainer = document.createElement("iframe");
    billingContainer.style.border = "none";
    billingContainer.setAttribute("width", dialog.clientWidth + "px");
    billingContainer.setAttribute("height", dialog.clientHeight - 50 + "px");
    billingContainer.src = `${apiUrl}/mybilling/members/${this.getCookie(
      this.sessionKey
    )}/profile`;
    dialog.appendChild(billingContainer);
  }

  private setLocalStorageConfig() {
    localStorage.setItem(this.storageConfigKey, JSON.stringify(this.dfConfig));
  }

  private setLocalStorageKey(key: string, obj: {}) {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  private loadAuthLock(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (window["Auth0LockPasswordless"]) {
        this.authLockPasswordless = window["Auth0LockPasswordless"];
        this.instantiateAuthLock();
        return resolve(true);
      }

      const script = document.createElement("script") as HTMLScriptElement;
      script.src = "https://cdn.auth0.com/js/lock/11.30/lock.min.js";

      script.onload = (ev) => {
        this.authLockPasswordless = window["Auth0LockPasswordless"];
        this.instantiateAuthLock();
        resolve(true);
      };

      script.onerror = (err) => {
        reject(err);
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  private instantiateAuthLock() {
    const checkRedirectStored = localStorage.getItem(this.storageRedirectKey);
    if (checkRedirectStored) {
      localStorage.removeItem(this.storageRedirectKey);
    }
    const locationWithoutLogin = window.location.href.replace("#login", "");

    const passwordlessOptions = {
      closable: false,
      allowedConnections: ["email"],
      passwordlessMethod: "link",
      auth: {
        redirectUrl:
          `${apiUrl}/member-auth/login-callback/${this.startupConfig.portalId}?redirectUrl=` +
          encodeURIComponent(checkRedirectStored || locationWithoutLogin),
      },
      languageDictionary: {
        title: this.startupConfig.loginTitle || "Log in",
        emailInputPlaceholder: "Your email",
        error: {
          login: {
            "lock.fallback":
              "We're sorry, something went wrong when attempting to log in. Please try again later or contact us.",
          },
        },
      },
      theme: {
        primaryColor: "#0059d6",
      },
      connectionResolver: (username, context, cb) => {
        if (this.errorContainer) {
          this.errorContainer.remove();
        }

        if (username) {
          return fetch(
            apiUrl +
              `/member-auth/crm-user-lookup?portalId=${this.startupConfig.portalId}&email=` +
              encodeURIComponent(username),
            {
              method: "GET",
            }
          )
            .then((response) => {
              if (!response.ok) {
                throw new Error("Invalid user");
              } else {
                cb();
              }
            })
            .catch((err: Error) => {
              const inputEl = document.querySelector(this.authInputClass);
              this.errorContainer = document.createElement("div");
              this.errorContainer.classList.add("df-error-container");
              this.errorContainer.innerHTML = err.message;

              inputEl.parentElement.appendChild(this.errorContainer);
              console.log(err.message, context);
            });
        }
      },
    };

    var lock = new this.authLockPasswordless(
      this.dfConfig.clientId,
      this.dfConfig.domain,
      passwordlessOptions
    );

    lock.show();
  }

  private loadAuth0WebAuth(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (window["auth0"]) {
        this.auth0 = window["auth0"];
        this.instantiateAuth0WebAuth();
        return resolve(true);
      }

      const script = document.createElement("script") as HTMLScriptElement;
      script.src = "https://cdn.auth0.com/js/auth0/9.11/auth0.min.js";

      script.onload = (ev) => {
        this.auth0 = window["auth0"];
        this.instantiateAuth0WebAuth();
        resolve(true);
      };

      script.onerror = (err) => {
        reject(err);
      };

      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  private instantiateAuth0WebAuth() {
    this.auth0WebAuth = new this.auth0.WebAuth({
      domain: this.dfConfig.domain,
      clientID: this.dfConfig.clientId
    });
  }

  private checkSession(): Promise<ICeStatus> {
    let sessionId = this.getAuthToken();

    if (!sessionId) {
      sessionId = `dfus_${this.startupConfig.portalId}`;
    }

    return fetch(`${ceApiUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: sessionId,
        requestedPath: window.location.pathname,
      }),
    }).then((response) => response.json());
  }

  private checkParams(paramKey: string): string {
    const params = new URL(window.location.href);
    const inUrl = params.searchParams.get(paramKey);
    return inUrl;
  }

  private getAuthToken(): string {
    const params = new URL(window.location.href);
    const inUrl = params.searchParams.get(this.sessionKey);
    if (inUrl) {
      document.cookie = `${this.sessionKey}=${inUrl}; expires=${this.getCookieExpiryDate()}; path=/`;
      params.searchParams.delete(this.sessionKey);
      window.history.replaceState({}, document.title, params.href);
      return inUrl;
    }

    const fromCookies = this.getCookie(this.sessionKey);
    if (fromCookies) {
      return fromCookies;
    }

    return null;
  }

  private getCookieExpiryDate(): string {
    const date = new Date();
    const expirationInDays = 30;
    date.setTime(date.getTime() + (expirationInDays * 24 * 60 * 60 * 1000));
    return date.toUTCString();
  }

  private getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  private triggerAppEvent(methodName: any, data?: any) {
    if (this.callbacks && this.callbacks[methodName]) {
      return this.callbacks[methodName](data);
    }
  }

}

(window as any).DFMembership = new DFMembership();
